import React from 'react'
import Navbar from '../components/Navbar';


const Journey = () => {
  return (
    <div>
        <Navbar/>
    </div>
  )
}

export default Journey
